<script>
import useHttp from '@/comp-functions/useHttp'
import router from '@/router'
import { ref,onMounted } from '@vue/composition-api'
import moment from 'moment'
import jsPDF from 'jspdf'

export default {
  setup() {
    const { $get, $post, $delete } = useHttp()
    const paramsId = router.currentRoute.params.id

    const dataEval = ref({
        company: {
        name: ''
      }
    })
    // evaluations
    console.log(dataEval.value, paramsId)
    const getDataPreview = async () => {
      const { data } =  await $get({
        url: `transaction/evaluations/${paramsId}`
      })
        dataEval.value = data
    }
    onMounted(async () => {
      moment.locale('ID')
      await getDataPreview()
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: 'a4'
      });

    doc.setProperties({
        title: "Evaluation Supplaier"
      });

      let xPos = 10
      let yPos = 10

      let xReactPos = 10
      let yReactPos = 10

      //revisi
      doc.rect(xReactPos + 140, yReactPos + 6, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 6, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("Revisi", xReactPos + 142, yReactPos + 10)
      doc.text("0", xReactPos + 166, yReactPos + 10)

      // tanggal
      doc.rect(xReactPos + 140, yReactPos + 11, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 11, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("Tanggal", xReactPos + 142, yReactPos + 15)
      doc.text("13/06/2011", xReactPos + 166, yReactPos + 15)

      // No
      doc.rect(xReactPos + 140, yReactPos + 16, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 16, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("No", xReactPos + 142, yReactPos + 20)
      doc.text("FORM-PRC-08", xReactPos + 166, yReactPos + 20)

      doc.setFontSize(17).setFont('','bold')
      xPos += 70
      yPos += 10
      doc.text("EVALUASI SUPPLIER", xPos - 15, yPos + 5)
      //   garis atas bawah judul 
      doc.line( (xPos + 120) , (yPos + 15) , (11 ), (yPos + 15) )
      //   garis atas kedua bawah judul
      doc.line( (xPos + 120) , (yPos + 16) , (11 ), (yPos + 16) )
      //   garis atas ketiga bawah judul
      doc.line( (xPos + 120) , (yPos + 23) , (11 ), (yPos + 23) )
      // garis keempat bawah judul
      doc.line( (xPos + 120) , (yPos + 30) , (11 ), (yPos + 30) )

      yPos += 4
      //nama perusahaan
      doc.setFontSize(10).setFont('','')
      doc.text("NAMA PERUSAHAAN ", xPos - 68, yPos + 17)
      doc.text(" : ", xPos - 22, yPos + 17)
      doc.text(" CV Jaya Abadi ", xPos - 19, yPos + 17)

     //jenis supplai
      doc.text("JENIS SUPPLAI ", xPos - 68, yPos + 24)
      doc.text(" : ", xPos - 22, yPos + 24)
      doc.text(" Sparepat Mobil ", xPos -19, yPos + 24)

      //periode
      doc.text("PERIODE ", xPos + 46, yPos + 17)
      doc.text(" : ", xPos + 63, yPos + 17)
      doc.text("1 Juli 2020 s/d 1 Desember 2020", xPos + 66, yPos + 17)

      //jenis supplai
      doc.text("NILAI PERIODE SEBELUMNYA", xPos + 46, yPos + 24)
      doc.text(" : ", xPos + 97, yPos + 24)
      doc.text("A", xPos + 100, yPos + 24)

      doc.setFontSize(10).setFont('','')

      // garis miring kiri
      doc.line(11, 35, 11, 207) 
      // garis miring tengah
      doc.line(125, 35, 125, 207) 
      // garis miring pojok
      doc.line(200, 35, 200, 207) 

      // garis bawah jenis supplai
      doc.line( (xPos + 45) , (yPos + 28) , (11 ), (yPos + 28) )
      // garis bawah nilai periode kebawah
      doc.line(125, 80, 125, 52) 
      // garis kanan samping no
      doc.line(17, 52, 17, 207) 
      doc.text("No. ", xPos - 68.5, yPos + 33)

      // garis bawahnya jenis supplai nomor 2
      doc.line( (xPos + 45) , (yPos + 33) , (17), (yPos + 33) )
      //garis bawahnnya supplai nomor 2
      doc.line( (xPos + 45) , (yPos + 38) , (11), (yPos + 38) )

      //looping garis
      let baris = 6
      for (let i = 0; i < 24; i++) {
          baris = baris + 6
         doc.line( (125) , (57 + baris) , (11), (57 + baris) )
      }

      doc.setFontSize(8).setFont('','')
      doc.text("FAKTOR PENILAIAN", xPos + 15, yPos + 31.5)

      //penilaian
      doc.setFontSize(11).setFont('','')
      doc.text("A", xPos + 15, yPos + 37)
      doc.text("B", xPos + 23, yPos + 37)
      doc.text("C", xPos + 31, yPos + 37)
      doc.text("D", xPos + 39, yPos + 37)

      doc.text("PO", xPos - 30, yPos + 31.5)
      //kolom no dan tanggal
      doc.text("No. ", xPos - 45, yPos + 37)
      doc.text("Tgl", xPos - 7, yPos + 37)

      //data no po looping
      doc.setFontSize(8).setFont('','')
      let isi = 0
      for (let i = 0; i < 24; i++) {
          isi = isi + 6
          let b = i + 1
          doc.text( `${b}` , 13, 61 + isi)
          doc.text("PO/PSS-HO/INV2020916-16", 18.5, 61 + isi)
          doc.text("24 September 2020", 68, 61 + isi)
      }
      //garis tengah no dan tanggal
      doc.line(60, 207, 60, 57) 
      doc.line(92, 207, 92, 52)
      
      // line kolom a
      doc.line(100, 207, 100, 57) 
      // line kolom b
      doc.line(108, 207, 108, 57) 
      // line kolom c
      doc.line(116, 207, 116, 57) 

    //   samping kanan nilai
      doc.line( (xPos + 116) , (yPos + 28) , (128), (yPos + 28))

      doc.line( (xPos + 116) , (yPos + 33) , (128), (yPos + 33))

    //garis Nilai
      doc.line(128, 100, 128, 52) 
      doc.line(133, 100, 133, 52) 
      doc.line(140, 100, 140, 52)

      doc.output('dataurlnewwindow');


    })
    

    return {
      delete: $delete,
      post: $post,
      get: $get,
    }
  }
}
</script>